import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/comite'
				,name: 'comite'
				,component: () => import('@/apps/comite/pages/Solicitudes')
				,meta: {
					title: 'Solicitudes - Comité'
					,middleware: auth
				}
			},
			{
				path: '/comite-cesion'
				,name: 'comite-cesion'
				,component: () => import('@/apps/comite/pages/SolicitudesCesion')
				,meta: {
					title: 'Solicitudes - Comité'
					,middleware: auth
				}
			}
			,{
				path: '/comite/historico'
				,name: 'comite_historico'
				,component: () => import('@/apps/comite/pages/Historico')
				,meta: {
					title: 'Solicitudes - Comité'
					,middleware: auth
				}
			}
			,{
				path: '/comite/solicitud/resumen/:id'
				,name: 'comite_resumen_modelo'
				,component: () => import('@/apps/comite/components/ResumenModelo.vue')
				,meta: {
					title: 'Detalle - Comité'
					,middleware: auth
				}
			}
			,{
				path: '/comite/solicitud/garantia'
				,name: 'comite_garantia'
				,component: () => import('@/apps/comite/components/Garantia.vue')
				,meta: {
					title: 'Detalle - Comité'
					,middleware: auth
				}
			}
			,{
				path: '/comite/solicitud/comportamiento'
				,name: 'comite_comportamiento'
				,component: () => import('@/apps/comite/components/Comportamiento.vue')
				,meta: {
					title: 'Detalle - Comité'
					,middleware: auth
				}
			}
			,{
				path: '/comite/solicitud/capacidad-pago'
				,name: 'comite_capacidad_pago'
				,component: () => import('@/apps/comite/components/CapacidadPago.vue')
				,meta: {
					title: 'Detalle - Comité'
					,middleware: auth
				}
			}
			,{
				path: '/comite/solicitud/decision-modelo'
				,name: 'comite_decision_modelo'
				,component: () => import('@/apps/comite/components/DecisionModelo.vue')
				,meta: {
					title: 'Detalle - Comité'
					,middleware: auth
				}
			}
			/**-------- OPEN BANKING DESHABILITADO ------------**/
			/**,{
				path: '/comite/solicitud/open-banking'
				,name: 'open_banking'
				,component: () => import('@/apps/comite/components/OpenBanking.vue')
				,meta: {
					title: 'Open - Banking'
					,middleware: auth
				}
			}**/
			,{
				path: '/comite/solicitud/historico-creditos-cesion'
				,name: 'historico_cesion_creditos'
				,component: () => import('@/apps/comite/pages/HistoricoCesionCreditos')
				,meta: {
					title: 'Cesión Creditos'
					,middleware: auth
				}
			},
			{
				path: '/comite/solicitud/solicitudes-a-ceder'
				,name: 'solicitudes_a_ceder'
				,component: () => import('@/apps/comite/pages/SolicitudesCeder')
				,meta: {
					title: 'Solicitudes a Ceder'
					,middleware: auth
				}
			},
			{
				path: '/comite/automatizacion/reglas'
				,name: 'reglas_automatizacion'
				,component: () => import('@/apps/comite/pages/automatizacionReglas')
				,meta: {
					title: 'Reglas de Automatización'
					,middleware: auth
				}
			},
			{
				path: '/comite/usuarios'
				,name: 'usuarios'
				,component: () => import('@/apps/comite/pages/Usuarios')
				,meta: {
					title: 'Usuarios'
					,middleware: auth
				}
			},
			{
				path: '/cesion/usuarios'
				,name: 'usuarios_cesion'
				,component: () => import('@/apps/comite/pages/UsuariosCesion')
				,meta: {
					title: 'Usuarios cesion'
					,middleware: auth
				}
			},

		]
	}
];